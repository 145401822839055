import 'lodash.debounce';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import { Swiper, Navigation, Pagination, Lazy} from '../util/swiper/js/swiper.esm.js';
Swiper.use([Navigation, Pagination, Lazy]);

// core version + navigation, pagination modules:
//import Swiper, { Navigation, Pagination, Lazy } from '../util/swiper/js/swiper.esm.js';
// configure Swiper to use modules
//Swiper.use([Navigation, Pagination, Lazy]);

import SmoothScroll from "smooth-scroll/dist/smooth-scroll.js";
import * as basicLightbox from 'basiclightbox';
import Colcade from 'colcade/colcade';
import getVideoId from 'get-video-id';

export default {
  init() {
    // JavaScript to be fired on all pages

    // console.log('common');
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    class Site {
      constructor() {
        this.state = {
          isOnTop: false
        };
        this.widgets = {};
        this.el = {
          body: document.body,
          header: document.getElementById('part_header'),
          navigation: document.getElementById('main_navigation'),
          menuButton: document.getElementById('mobile_menu_button'),
          logo: document.querySelector('.c-logo'),
          image_gallery: document.getElementById('artist_exhibition_image_gallery'),
          opening_image_gallery: document.getElementById('opening_image_gallery'),
          opening_video_gallery: document.getElementById('opening_video_gallery')
        };

        this.scroll = new SmoothScroll('a[href*="#"]', {
          speed: 500,
          speedAsDuration: false,
          easing: 'easeInOutQuad'
        });

        this.checkHeader();
        this.bindEvents();
        this.initWidgets();
        this.addExhibitionsActive();
        this.addArtworksActive();
		this.addFotogaleriakActive();
      };

      setState(state) {
        const oldState = this.state;
        this.state = {...this.state, ...(state || {})};
        this.updateHeader(this.state, oldState);
      };

      checkHeader() {
        if (window.scrollY > 32) {
          this.setState({isOnTop: false});
        }
      };

      addExhibitionsActive() {
        if (window.location.href.indexOf("/kiallitasok/") > -1) {
          document.querySelector('.c-main-navigation__item--exhibitions').classList.add('current_page_item');
        }
      };

      addArtworksActive() {
        if (window.location.href.indexOf("/mutargyak/") > -1) {
          document.querySelector('.c-main-navigation__item--artworks').classList.add('current_page_item');
        }
      };
		
	  addFotogaleriakActive() {
        if (window.location.href.indexOf("/fotogaleriak/") > -1) {
          document.querySelector('.c-main-navigation__item--fotogaleriak').classList.add('current_page_item');
        }
      };

      updateHeader(state, oldState) {
        if (this.el.navigation) {
          if (state.isOnTop !== oldState.isOnTop) {
            if (state.isOnTop) {
              this.el.logo.classList.remove('c-logo--resized');
              this.el.navigation.classList.remove('c-main-navigation--resized');
              this.el.header.classList.remove('l-header--resized');
            } else {
              this.el.logo.classList.add('c-logo--resized');
              this.el.navigation.classList.add('c-main-navigation--resized');
              this.el.header.classList.add('l-header--resized');
            }
          } else {
            if (!state.isOnTop) {
              this.el.logo.classList.add('c-logo--resized');
              this.el.navigation.classList.add('c-main-navigation--resized');
              this.el.header.classList.add('l-header--resized');
            }
          }
        }
      };

      onScrollImmediately() {
        if (window.scrollY > 32) {
          if (this.state.isOnTop) {
            this.setState({isOnTop: false});
          }
        }
        else {
          if (!this.state.isOnTop) {
            this.setState({isOnTop: true});
          }
        }
      };

      bindEvents() {
        window.addEventListener('scroll', e => {
          this.onScrollImmediately(e);
          // console.log(this.state);
        });
        window.addEventListener('resize', e => {
          // this.events.onResize(e);
          // console.log(e.target.innerWidth);
          // console.log(e.target.innerHeight);
        });
        this.el.menuButton.addEventListener('click', e => {
          this.el.menuButton.classList.toggle('c-menu-button--active');
          this.el.navigation.classList.toggle('c-main-navigation--opened');
          document.body.classList.toggle('non-scrolling');
        });

        if (this.el.image_gallery) {
          const image_gallery_elemets = this.el.image_gallery.getElementsByClassName('c-image-gallery__item');
          for (let item of image_gallery_elemets) {
            item.addEventListener('click', e => {
              let itemImageSrc = item.querySelector('.c-image-gallery__image').getAttribute('data-large');
              let instance = basicLightbox.create(
                `<img src="${itemImageSrc}">`
              );
              let closeButtonContainerElement = document.createElement('div');
              closeButtonContainerElement.classList.add('basicLightbox__button-wrapper');
              closeButtonContainerElement.innerHTML = `<button class="basicLightbox__close-button basicLightbox__close-button--active" type="button"><span class="basicLightbox__close-button__bar"></span></button>`;
              instance.show();
              let basicLightboxElement = document.querySelector('.basicLightbox');
              basicLightboxElement.appendChild(closeButtonContainerElement);
              basicLightboxElement.addEventListener('click', function(){
                instance.close()
              });
            });
          }
        }

        if (this.el.opening_image_gallery) {
          const opening_image_gallery_elemets = this.el.opening_image_gallery.getElementsByClassName('c-image-gallery__item');
          for (let item of opening_image_gallery_elemets) {
            item.addEventListener('click', e => {
              let itemImageSrc = item.querySelector('.c-image-gallery__image').getAttribute('data-large');
              let instance = basicLightbox.create(
                `<img src="${itemImageSrc}">`
              );
              let closeButtonContainerElement = document.createElement('div');
              closeButtonContainerElement.classList.add('basicLightbox__button-wrapper');
              closeButtonContainerElement.innerHTML = `<button class="basicLightbox__close-button basicLightbox__close-button--active" type="button"><span class="basicLightbox__close-button__bar"></span></button>`;
              instance.show();
              let basicLightboxElement = document.querySelector('.basicLightbox');
              basicLightboxElement.appendChild(closeButtonContainerElement);
              basicLightboxElement.addEventListener('click', function(){
                instance.close()
              });
            });
          }
        }

        if (this.el.opening_video_gallery) {
          const opening_video_gallery_elemets = this.el.opening_video_gallery.getElementsByClassName('c-opening-videos__item');
          for (let item of opening_video_gallery_elemets) {
            item.addEventListener('click', e => {
              let thumbnailSrc = item.querySelector('.c-opening-videos__image').getAttribute('src');
              let videoId = getVideoId(thumbnailSrc).id;
              let videoSrc = `https://www.youtube-nocookie.com/embed/${videoId}`
              let instance = basicLightbox.create(
                `<iframe src="${videoSrc}" width="560" height="315" frameborder="0"></iframe>`
              );
              let closeButtonContainerElement = document.createElement('div');
              closeButtonContainerElement.classList.add('basicLightbox__button-wrapper');
              closeButtonContainerElement.innerHTML = `<button class="basicLightbox__close-button basicLightbox__close-button--active" type="button"><span class="basicLightbox__close-button__bar"></span></button>`;
              instance.show();
              let basicLightboxElement = document.querySelector('.basicLightbox');
              basicLightboxElement.appendChild(closeButtonContainerElement);
              basicLightboxElement.addEventListener('click', function(){
                instance.close()
              });
            });
          }
        }
      };

      initWidgets() {
        this.widgets.newsSwiper = new Swiper ('.swiper-container', {
          preloadImages: false,
          lazy: true,
          loop: false,
          slidesPerView: 1,

          breakpoints: {
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
          a11y: {
            prevSlideMessage: 'Előző hír',
            nextSlideMessage: 'Következő hír',
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            dynamicBullets: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });

        if(this.el.image_gallery) {
          this.widgets.colcadeExhibitionGallery = new Colcade( '#artist_exhibition_image_gallery .c-image-gallery__list', {
            columns: '.c-image-gallery__column',
            items: '.c-image-gallery__item'
          });
        }
        if(this.el.opening_image_gallery) {
          this.widgets.colcadeOpeningGallery = new Colcade( '#opening_image_gallery .c-image-gallery__list', {
            columns: '.c-image-gallery__column',
            items: '.c-image-gallery__item'
          });
        }
      };
    }
    const abigailSite = new Site;
    // console.log(abigailSite)
  },
};
